<template>
  <div>
    <b-row
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="content-header"
    >

      <!-- Content Left -->
      <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                      class="align-text-top"
                      icon="HomeIcon"
                      size="16"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                    v-for="item in $route.meta.breadcrumb"
                    :key="item.text"
                    :active="item.active"
                    :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right d-md-block d-none mb-1" md="3" cols="12">
        <b-button variant="success" v-b-modal:modal-1>
          New App
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-1" title="New App" ok-title="Create" centered @ok="createApp">
      <b-card-text>

        <validation-observer ref="createAppForm" #default="{invalid}">
          <b-form class="auth-login-form mt-2"
                  @submit.prevent
          >
            <!-- email -->
            <b-form-group
                label-for="nm_appname"
                label="App Name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="App Name"
                  rules="required"
              >
                <b-form-input
                    id="nm_appname"
                    v-model="nm_appname"
                    name="nm_appname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="My Awesome App"
                    autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>

</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@core/utils/validations/validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      nm_appname: '',

      required
    }
  },
  methods: {
    createApp(bvModalEvent) {

      this.$http.post('/app/create', { app_name: this.$data.nm_appname })
          .then(async (response) => {
            if (!response) return

            await this.$router.push('/apps/' + response.data.id)
          })
          .catch((error) => {
            console.log(error.response.data.errors)
          })
      bvModalEvent.preventDefault()
    }
  }
}
</script>
